<template>
  <ion-page>
    <page-header parentPath="/places" title="地點比較">
    </page-header>

    <ion-content>
      <ion-grid fixed>

        <!-- Loading Data -->
        <loading-skeleton v-if="loading"></loading-skeleton>

        <!-- Data loaded -->
        <div v-else>
          
          <table border="1" class="styled-table">
            <thead>
              <tr>
                <th></th>
                <th v-for="place in comparingPlaces" :key="place.id">{{ place["名稱"] }}</th>
              </tr>
            </thead>
            
            <tbody>
              <tr v-for="metric in comparingMetrics" :key="metric.key">
                <td>{{ metric.label }}</td>
                <td v-for="place in comparingPlaces" :key="place.id">
                  {{ place[metric.key] || "-" }}
                  <span v-if="place[metric.key]">
                    <span v-if="metric.key == 'fbRating'"> / 5</span>
                    <span v-if="metric.key == 'gmRating'"> / 5</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
         swapVerticalOutline, } from 'ionicons/icons';

// components
import { IonPage, IonSearchbar, IonContent, IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonSegment, IonSegmentButton, IonChip, IonBackButton,
        IonAvatar, IonSelect, IonSelectOption, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import PlaceItem from "@/components/PlaceItem.vue";

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'ComparisonPage',
  components: { IonSearchbar, IonContent, IonPage, IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonSegment, IonSegmentButton, IonChip, IonBackButton,
                IonAvatar, IonSelect, IonSelectOption, 
                LoadingSkeleton, PlaceItem,
              },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const placeIds = (router.currentRoute.value.query.placeIds || "").split(",").filter(p => !!p);
    
    const comparingMetrics = [
      { key: 'fbLikes', label: 'FB 讚好數' },
      { key: 'fbRating', label: 'FB 評分' },
      { key: 'igFollowers', label: 'IG 追蹤人數' },
      { key: 'gmRating', label: 'Google Maps 評分' },
      { key: '成立年份', label: '成立年份' },
    ]

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const comparingPlaces = computed(() => store.getters.getPlacesByIds(placeIds));

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t,

      // icons
      add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
      swapVerticalOutline,

      // variables
      loading, comparingPlaces, comparingMetrics,
    }
  }
}
</script>

<style scoped>
  table {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .styled-table {
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  .styled-table th {
    padding: 10px;
    min-width: 100px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  .styled-table td {
    padding: 12px 15px;
  }
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  .styled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
  }
  .styled-table tbody tr:last-of-type {
      border-bottom: 2px solid #009879;
  }
</style>